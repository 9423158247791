import React from "react"
import { Link } from "gatsby"

import { styled } from "@lib/theme"

const Nav = styled.nav`
    padding: 10px 0;
`

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const ListItem = styled.li`
    display: inline-block;
    margin-right: 20px;
`

export interface INavigationProps {
    children: React.ReactNode
}

const NavigationComponent = ({ children }: INavigationProps) => (
    <Nav>
        <List>{children && React.Children.map(children, (child, i) => <ListItem key={i}>{child}</ListItem>)}</List>
    </Nav>
)

NavigationComponent.Link = styled(Link)`
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
    font-family: ${(props) => props.theme.fonts.heading};
    font-weight: bold;

    &:hover {
        color: ${(props) => props.theme.colors.blue};
    }
`

export const Navigation = NavigationComponent
