import React from "react"
import { Box, SxStyleProp } from "rebass"

import { Footer } from "@components/Footer"
import { Header } from "@components/Header"
import { Navigation } from "@components/Navigation"

const wrapperCss: SxStyleProp = {
    marginTop: "30px",
}

const mainCss: SxStyleProp = {
    width: "calc(100% - 55px)",
    maxWidth: "1400px",
}

export interface ILayoutProps {
    children: React.ReactNode
    hideNavigation?: boolean
}

const Layout = ({ children, hideNavigation }: ILayoutProps) => (
    <Box padding="25px">
        {!hideNavigation && (
            <Navigation>
                <Navigation.Link to="/">Home</Navigation.Link>
                <Navigation.Link to="/feed">Feed</Navigation.Link>
                <Navigation.Link to="https://instagram.com/zidizei">@zidizei</Navigation.Link>
            </Navigation>
        )}
        <Header siteTitle="LST&FND" />
        <Box sx={wrapperCss}>
            <Box as="main" sx={mainCss}>
                {children}
            </Box>
        </Box>
        <Footer />
    </Box>
)

export default Layout
